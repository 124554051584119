import styled from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { SUBContextProvider } from "@reasongcp/react-fire-sub";
import {
  AppToasts,
  AppToastProvider,
  EnvBanner,
  toastComponents,
  AppModalProvider,
  AppModal,
} from "@taxscribe/ui";
import { TaxbaseApolloProvider } from "@taxscribe/apollo-helpers";

import AppRoutes from "./components/shared/AppRoutes";
import config from "./config";
import { FC } from "react";
import AppTheme from "./components/shared/Theme";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const App: FC = () => {
  return (
    <AppTheme>
      <AppWrapper>
        <AppToastProvider
          position="top-end"
          defaultTimeout={3000}
          ToastComponents={toastComponents}
        >
          <SUBContextProvider
            config={config.firebaseConfig}
            authCookieName={config.authCookieName}
            emulate={config.releaseStage === "development"}
          >
            <EnvBanner releaseStage={config.releaseStage as any} />
            <TaxbaseApolloProvider uri={config.taxbaseUri}>
              <AppModalProvider>
                <Router>
                  <AppRoutes />
                </Router>
                <AppModal />
              </AppModalProvider>
            </TaxbaseApolloProvider>
          </SUBContextProvider>
          <AppToasts />
        </AppToastProvider>
      </AppWrapper>
    </AppTheme>
  );
};

export default App;
